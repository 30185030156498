import React, { useState, useRef } from "react";
import axios from "axios";
import Joi from "joi";
import { motion } from "framer-motion";
import { fadeIn } from "../variants";
import { toast } from "react-hot-toast";
import aboutImage1 from "../assets/images/6.png";
import worldMap from "../assets/images/cg-aboutus-map.gif";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import helpers from "../services/cryptos";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const recaptchaRef = useRef(null);
  const cValue = process.env.REACT_APP_CAPTCHA;
  const defaultUrl = process.env.REACT_APP_API_URL;
  const capp =
    "U2FsdGVkX19/fS1OTDVoxd+fGoJycQ+QEZM0NQtmyEMbjF7lhhesFlTdL1MzLHZF";
  const devCondition = process.env.REACT_APP_API_NODE_ENV;

  const [form, setForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    message: "",
    phone: "",
    captcha: devCondition === "dev" ? capp : "",
  });

  const [errors, setErrors] = useState({});

  // Validation schema
  const contactSchema = Joi.object({
    first_name: Joi.string()
      .pattern(/^[a-zA-Z\s]*$/)
      .trim()
      .min(3)
      .max(10)
      .messages({
        "string.pattern.base":
          "First Name should only contain letters and spaces",
      })
      .required(),
    last_name: Joi.string()
      .pattern(/^[a-zA-Z\s]*$/)
      .trim()
      .min(3)
      .max(10)
      .messages({
        "string.pattern.base":
          "Last Name should only contain letters and spaces",
      })
      .required(),
    email: Joi.string()
      .pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
      .trim()
      .min(3)
      .max(55)
      .email({ tlds: { allow: ["com", "net", "org", "io", "co", "us"] } })
      .required()
      .label("Email")
      .messages({
        "string.pattern.base": "Email must be valid email",
      }),
    phone: Joi.string()
      .pattern(/^\+?\d{1,3}[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/)
      .required()
      .custom((value, helpers) => {
        if (value[1] === "0") {
          return helpers.message("Phone number must not start with 0.");
        }
        return value;
      })
      .label("Phone")
      .messages({
        "string.pattern.base":
          "Phone number must include country code and be valid.",
      }),

    message: Joi.string()
      .pattern(/^[a-zA-Z0-9\s.,!@()-:;"']*$/)
      .trim()
      .min(20)
      .max(500)
      .messages({
        "string.pattern.base": "Special characters are not allowed",
      })
      .required(),
    captcha: Joi.string().trim().min(3).required().label("Captcha"),
  });

  const validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = Joi.object({ [name]: contactSchema.extract(name) });
    const { error } = schema.validate(obj);
    if (error) {
      const errorMessage = error.details[0].message;
      if (name === "first_name") {
        return errorMessage.replace("first_name", "First Name");
      }
      if (name === "last_name") {
        return errorMessage.replace("last_name", "Last Name");
      }
      return errorMessage;
    }
    return null;
  };

  // Handle changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });

    const errorMessage = validateProperty(e.target);
    if (errorMessage) setErrors({ ...errors, [name]: errorMessage });
    else {
      const newErrors = { ...errors };
      delete newErrors[name];
      setErrors(newErrors);
    }
  };

  // Handle phone change
  const handlePhoneChange = (value) => {
    const phoneWithPlus = `+${value}`;
    setForm({ ...form, phone: phoneWithPlus });

    const errorMessage = validateProperty({
      name: "phone",
      value: phoneWithPlus,
    });
    if (errorMessage) setErrors({ ...errors, phone: errorMessage });
    else {
      const newErrors = { ...errors };
      delete newErrors.phone;
      setErrors(newErrors);
    }
  };

  // Validate the errors
  const validate = () => {
    const { error } = contactSchema.validate(form, { abortEarly: false });
    if (!error) return null;

    const validationErrors = {};
    error.details.forEach((detail) => {
      let errorMessage = detail.message;
      if (detail.path[0] === "first_name") {
        errorMessage = errorMessage.replace("first_name", "First Name");
      }
      if (detail.path[0] === "last_name") {
        errorMessage = errorMessage.replace("last_name", "Last Name");
      }
      validationErrors[detail.path[0]] = errorMessage;
    });
    return validationErrors;
  };

  // Handle submit
  const handleSubmit = async (e) => {
    e.preventDefault();

    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setErrors({ captcha: "Please complete the CAPTCHA" });
      return;
    }

    const validationErrors = validate();
    if (validationErrors) {
      setErrors(validationErrors);
      return;
    }

    try {
      setLoading(true);
      const enc = await helpers.encryptobj(form);
      const response = await axios.post(`${defaultUrl}/member/cg_contact_us`, {
        enc,
      });
      await helpers.decryptobj(response.data);
      toast.success("Successfully submitted.");
      setForm({
        first_name: "",
        last_name: "",
        email: "",
        message: "",
        phone: "",
        captcha: "",
      });
      console.log(form);
      setTimeout(() => navigate("/"), 2500);
    } catch (error) {
      setLoading(false);
      toast.error("Error while submitting. Please try again.");
    }
  };

  const addresses = [
    {
      text: "Reg: (EN:37-2050121), 30N GOULD ST, STE R , SHERIDAN , Wyoming, United States -82801",
    },
    {
      text: "P2AG-F09, Building 1,Dubai Industrial City , Sheikh Mohammed Bin Zayed Rd - Dubai - United Arab Emirates",
    },
    {
      text: "Room 411, 4th Floor, First Intramuros BF Condominium Corp., Aduana St, Intramuros, Manila, Philippines, 1002",
    },
    {
      text: "DivyaSree Solitaire, 15, Inorbit Mall Rd, Madhapur, Hyderabad, Telangana, India 500081",
    },
    {
      text: "Main Road, Currency Nagar, Vijayawada, Andhra Pradesh, India 520008",
    },
  ];

  return (
    <div className="container">
      <section>
        <div className="row apply_form mb-5 px-3">
          <motion.div
            // variants={fadeIn("left", 0.2)}
            // initial="hidden"
            // whileInView={"show"}
            // viewport={{ once: true, amount: 0.7 }}
            className="col-lg-7"
          >
            <h3 className="mb-1 mt-5">Let’s have a chat about your growth</h3>
            <p className="text-muted mb-4">
              There is a talent waiting to work with a brand like yours.
            </p>
            <form
              onSubmit={handleSubmit}
              className="shadow-sm bg-white border p-4 mb-4"
            >
              <div className="row ">
                <div className="col-lg-6 col-12">
                  <div className="mb-3 form">
                    <label htmlFor="first_name" className="form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      value={form.first_name}
                      onChange={handleChange}
                      placeholder="First Name"
                      autoFocus
                      className="form-control"
                      id="first_name"
                      required
                    />

                    {errors.first_name && (
                      <p className="text-danger validation-errors">
                        {errors.first_name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="mb-3 form">
                    <label htmlFor="last_name" className="form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      value={form.last_name}
                      onChange={handleChange}
                      placeholder="Last Name"
                      className="form-control"
                      id="last_name"
                      required
                    />

                    {errors.last_name && (
                      <p className="text-danger validation-errors">
                        {errors.last_name}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="mb-3 form">
                    <label htmlFor="phone" className="form-label">
                      Phone Number <span className="text-danger">*</span>
                    </label>
                    <PhoneInput
                      type="text"
                      name="phone"
                      placeholder="Phone"
                      id="phone"
                      required
                      country={"us"}
                      value={form.phone}
                      onChange={handlePhoneChange}
                    />

                    {errors.phone && (
                      <p className="text-danger validation-errors">
                        {errors.phone}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="mb-3 form">
                    <label htmlFor="email" className="form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={form.email}
                      onChange={handleChange}
                      placeholder="Email"
                      className="form-control"
                      id="email"
                      required
                    />

                    {errors.email && (
                      <p className="text-danger validation-errors">
                        {errors.email}
                      </p>
                    )}
                  </div>
                </div>
                <div className="col-xl-12">
                  <div className="mb-3 form">
                    <label htmlFor="message" className="form-label">
                      Message <span className="text-danger">*</span>
                    </label>
                    <textarea
                      name="message"
                      value={form.message}
                      onChange={handleChange}
                      placeholder="Message"
                      className="form-control"
                      id="message"
                      required
                    />
                    {errors.message && (
                      <p className="text-danger validation-errors">
                        {errors.message}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={cValue}
                    onChange={(cpatchaValue) =>
                      setForm({ ...form, captcha: cpatchaValue })
                    }
                  />
                  {errors.captcha && (
                    <p className="text-danger validation-errors">
                      {errors.captcha}
                    </p>
                  )}
                </div>
              </div>
              <button
                disabled={validate() || loading}
                className="btn cg_btn_gradient btn-lg submit_button rounded-0 border-0 contact_us_btn mt-4"
                type="submit"
              >
                {loading ? (
                  <div
                    className="spinner-border text-light spinner-border-sm"
                    role="status"
                  ></div>
                ) : (
                  <>
                    Submit{" "}
                    <i className="bi bi-arrow-up-right-circle-fill fs-20"></i>
                  </>
                )}
              </button>
            </form>
          </motion.div>
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="col-lg-5 my-auto text-end"
          >
            <div className="text-center mt-5 mt-lg-0">
              <img src={aboutImage1} alt="img" className="animated_gif" />
            </div>
          </motion.div>
        </div>
      </section>

      <section className="mb-0">
        <div className="row">
          <motion.div
            variants={fadeIn("right", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="col-lg-5"
          >
            <h3 className="mb-4 main-info">Our Locations</h3>
            <ul className="list-group">
              {addresses.map((address, index) => (
                <li className="list-group-item px-0" key={index}>
                  <p className="fw-normal d-flex gap-2">
                    <i className="ri-map-pin-line text-primary fs-17 align-middle"></i>
                    <span className="fw-medium">{address.text}</span>
                  </p>
                </li>
              ))}
            </ul>
          </motion.div>
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="hidden"
            whileInView={"show"}
            viewport={{ once: true, amount: 0.7 }}
            className="col-lg-7 mt-4 my-lg-auto"
          >
            <img src={worldMap} alt="img" className="world-map" />
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
